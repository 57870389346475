import { CheckInTasksModalButtonTasks } from './CheckInTasksModalButton.types';
import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { CheckInTasksCacheKeys } from '../../../tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { BatchCreateSmartContractSharesModalButton } from '../../../../smartContractShares/components/modalButtons/BatchCreateSmartContractSharesModalButton';

interface CheckInTasksModalButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  i18nTitle?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tasks: CheckInTasksModalButtonTasks;
  cacheKeys?: CheckInTasksCacheKeys;
  tooltipI18nText?: I18nText;
  afterCreate?: () => void;
}

function CheckInTasksModalButton({
  className,
  i18nText,
  i18nTitle,
  icon,
  iconClassName,
  tasks,
  cacheKeys,
  tooltipI18nText,
  afterCreate
}: CheckInTasksModalButtonProps) {
  const currentUser = useCurrentUser();

  return (
    <BatchCreateSmartContractSharesModalButton
      className={className}
      tasks={tasks}
      tooltipI18nText={tooltipI18nText}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      i18nTitle={i18nTitle}
      afterCreate={afterCreate}
      cacheKeys={cacheKeys}
      defaultUsers={[currentUser]}
    />
  );
}

export default CheckInTasksModalButton;
